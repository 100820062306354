import { useEffect } from "react";
import mainBg from "../supporting_files/images/main_bg.jpg";

const FlufView = () => {
  useEffect(() => {
    document.title = "FLUF - Fluff Money";
  });

  return (
    <div id="wrapper" className="clearfix" style={{ backgroundColor: "#000" }}>
      <section
        id="slider"
        className="slider-element slider-parallax min-vh-60 min-vh-md-100 include-header"
      >
        <div
          className="slider-inner-element"
          style={{
            background: "url(" + mainBg + ") center center no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="vertical-middle slider-element-fade">
            <div className="container dark center">
              <div style={{ margin: "32px 0" }}>
                <div className="emphasis-title">
                  <p>
                    <img
                      id="main-logo"
                      src={
                        require("../supporting_files/images/logo.svg").default
                      }
                      height="100"
                    />
                  </p>
                  <h1>Fluff Money Token ($FLUF)</h1>
                </div>
                <div id="main-buttons" style={{ margin: "auto" }}>
                  <a
                    href="/fluf/buy"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Buy/Sell on SOL
                  </a>

                  <a
                    href="https://pancakeswap.finance/?outputCurrency=0x5285E2598FD8eBEAbF808AD5561Be7F03E1c78af&inputCurrency=BNB"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Buy/Sell on BSC
                  </a>
                  <a
                    href="/fluf/stake"
                    className="button button-large button-light"
                  >
                    Stake (BSC only)
                  </a>
                  <a
                    href="/fluf/charts"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Charts (SOL)
                  </a>
                  <a
                    href="https://dexscreener.com/bsc/0x07fda3b30d2545bdeb7ddf90ef5c3ab03bafefff"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Charts (BSC)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div id="contracts" className="page-section">
              <div className="row clearfix align-items-center center">
                <div className="emphasis-title">
                  <h2>Contracts</h2>
                </div>

                <h3>SOL (actively in use)</h3>

                <p className="contract-address">
                  <a
                    href="https://solscan.io/token/7F5274YYzuBhhmCpAHLKf5qn1KaDS9CwxL6wwXFTpump"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <code>7F5274YYzuBhhmCpAHLKf5qn1KaDS9CwxL6wwXFTpump</code>
                  </a>
                </p>

                <p>
                  The SOL relaunch was fucked up. I prematurely sold my BSC
                  allocation to fund the SOL version (this wasn't even announced
                  properly), which went to 150k and back to 6k!! This resulted
                  in both SOL and BSC people getting angry because they saw two
                  tokens that dumped massively.
                </p>

                <p>
                  Lots of people did not fill out the airdrop form properly (or
                  at all) so only 3 people received the airdrop (and even they
                  received it after the 150k spike, which wasn't too ideal
                  either.) I even fucked up with not paying for Dexscreener in
                  time and sending funds to burn to{" "}
                  <code className="contract-address">
                    1nc1nerator11111111111111111111111111111111
                  </code>{" "}
                  instead of burning them properly (I burned 28.2%).
                </p>

                <p>Moral of the story: prepare for the WORST</p>

                <h3>BSC (legacy, the SOL version gets more attention)</h3>
                <p>
                  The BSC token had 5 different versions. (I know, looks
                  horrible, but I can't do anything at this point.)
                </p>
                <p>
                  All v4 holders got v5 at the time of the release, all v4
                  holders got v3 and so on.
                </p>

                <div>
                  <strong>
                    FLUF v5 (made because there was a bug in FLUF v4's code that
                    made transaction fees rise after each transaction):
                  </strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0x5285E2598FD8eBEAbF808AD5561Be7F03E1c78af"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <code>0x5285E2598FD8eBEAbF808AD5561Be7F03E1c78af</code>
                    </a>
                  </p>
                </div>
                <div>
                  <strong>
                    FLUF v4 (added new features to the contract - most
                    importantly, a time-based buy/sell limit):
                  </strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0xf967a0Bc934a432C1De2b2329Ba9bdd78b5605B8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <code>0xf967a0Bc934a432C1De2b2329Ba9bdd78b5605B8</code>
                    </a>
                  </p>
                </div>
                <div>
                  <strong>FLUF v3 (made to add more BNB to the pool):</strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0xF3B978e37E85391E8CAd6bd06A6a093C06f54eDC"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <code>0xF3B978e37E85391E8CAd6bd06A6a093C06f54eDC</code>
                    </a>
                  </p>
                </div>
                <div>
                  <strong>
                    FLUF v2 (made because it's not possible to rename an ERC20
                    token):
                  </strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0xa153049830ef4f8af5ca7272f8a3aa056bd21914"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <code>0xa153049830ef4f8af5ca7272f8a3aa056bd21914</code>
                    </a>
                  </p>
                </div>
                <div>
                  <strong>FLUF v1 (YIAY):</strong>
                  <br />
                  <p className="contract-address">
                    <a
                      href="https://bscscan.com/token/0x3a42a4d32a811ce89e9bdc69a2d1c517314055aa"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <code>0x3a42a4d32a811ce89e9bdc69a2d1c517314055aa</code>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="content-wrap">
          <div className="container clearfix">
            <div id="contracts" className="page-section">
              <div className="row clearfix align-items-center center">
                <div className="emphasis-title">
                  <h2>Buybacks</h2>
                </div>
                <p>
                  The buybacks are done by the team, and the buybacks are done
                  by the team. i am making the website in cursor and it REALLY
                  wanted to make that previous sentence as an autocompletion
                  suggestion and i dont want ai to vaporize me so i just let it
                  happen.
                </p>
                <p>
                  buybacks are a late, long-unfulfilled promise. i split
                  buybacks between SOL and BSC in half, both of which are now
                  dead. this was another autocompleted claude sentence and it
                  fucking hurts but it's true. thank you claude for giving me
                  clarity in this dark world.
                </p>
                <p>
                  current state of the buybacks: 17.18 BNB more and 48.595 SOL
                  more
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="content-wrap">
          <div className="container clearfix">
            <div id="contracts" className="page-section">
              <div className="row clearfix align-items-center center">
                <div className="emphasis-title">
                  <h2>Team Wallets</h2>
                </div>

                <h3>Main Dev Wallet</h3>
                <a
                  className="contract-address"
                  href="https://solscan.io/address/bzuNzCMgxRgWuDhg9yBTsto9rkNL96LsVWo6YurNQQf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <code>bzuNzCMgxRgWuDhg9yBTsto9rkNL96LsVWo6YurNQQf</code>
                </a>

                <h3>Personal Team Wallets</h3>
                <a
                  className="contract-address"
                  href="https://solscan.io/address/3wyNrGmrUc16mYzPAS3h4k5C6tywF5Hha3nKgXUiMBkx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <code>3wyNrGmrUc16mYzPAS3h4k5C6tywF5Hha3nKgXUiMBkx</code>
                </a>

                <a
                  className="contract-address"
                  href="https://solscan.io/address/BvNUkCrCDwQyXAKYTd7WsVuCvQA41zqaE7hdjvWZdW19"
                  target="_blank"
                  rel="noreferrer"
                >
                  <code>BvNUkCrCDwQyXAKYTd7WsVuCvQA41zqaE7hdjvWZdW19</code>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FlufView;
